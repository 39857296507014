//
// Main
//

// Body
body {
	background-color: var(--#{$prefix}header-default-bg-color);
}

// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

.app-blank {
	background-color: var(--#{$prefix}app-blank-bg) !important;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		// Aside default enabled and aside fixed modes
		.aside-enabled.aside-fixed & {
			padding-left: get($aside-config, width, desktop);
		}

		// Fixed header mode
		.header-fixed & {
			padding-top: get($header-config, fixed, height, desktop);
		}

		// Fixed header & fixed toolbar modes
		.header-fixed.toolbar-fixed & {
			padding-top: get($header-config, fixed, height, desktop) + get($toolbar-config, height, desktop);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed & {
			padding-top: get($header-config, fixed, height, tablet-and-mobile);
		}

		// Fixed header & fixed toolbar modes
		.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
			padding-top: get($header-config, fixed, height, tablet-and-mobile) + get($toolbar-config, height, tablet-and-mobile);
		}
	}
}
