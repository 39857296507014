//
// Menu
//

// Aside menu
.aside-menu {
    .hover-scroll-y,
    .hover-scroll-overlay-y {
        --#{$prefix}scrollbar-space: 0.4rem;
        @include scrollbar-color(#3b3b64, lighten(#3b3b64, 3%));
    }

    // Basic
    .menu {
        .menu-section {
            color: #4c4e6f !important;
        }

        > .menu-item {
			> .menu-link {
				width: 100%;
                height: 40px;
                margin: 0 auto; 
                flex-direction: column;
                display: flex;     
                border-radius: 0.475rem 0 0 0.475rem;   

				.menu-icon {					
					justify-content: center;
                    width: 0; 
                    margin-bottom: 0.5rem;                  
                    
                    i {                        
                        font-size: 26px !important;				
                    }					
				} 

                .menu-title {					
					flex-grow: 0;                 
				}   
			}       

            // &.here > .menu-link,
            // & > .menu-link.active {
            //     border: 1px solid $aisquare-aside-primary;
            // }      

            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color, $all-links
            @include menu-link-default-state( $aisquare-aside-text-primary, $gray-300, $gray-300, $gray-300, null, false);
            @include menu-link-hover-state( $aisquare-aside-primary, var(--#{$prefix}primary), var(--#{$prefix}primary), var(--#{$prefix}primary), $aisquare-menu-link-active, false);
            @include menu-link-here-state( $gray-900-dark, var(--#{$prefix}primary), var(--#{$prefix}primary), var(--#{$prefix}primary), $aisquare-aside-primary, false);
            @include menu-link-show-state( $aisquare-aside-primary, var(--#{$prefix}primary), var(--#{$prefix}primary), var(--#{$prefix}primary), $aisquare-menu-link-active, false);
            @include menu-link-active-state( $gray-900-dark, var(--#{$prefix}primary), var(--#{$prefix}primary), var(--#{$prefix}primary), $aisquare-aside-primary , false);
		}
    }     
}